import { Fragment, useMemo } from 'react';
import dynamic from 'next/dynamic';
import theme from '@mui/theme';
import Typography from '@mui/material/Typography';
import { FieldParagraphProps } from '@model/paragraph-props.model';
import { DocumentCollectionModel } from '@model/document-collection.model';
import { absoluteUrl, transformTitleId } from '@utils/functions/utils';
import { getFieldLink } from '@utils/functions/getFieldLink';

const Grid = dynamic(() => import('@mui/material/Grid'));
const GridContainer = dynamic(() => import('@atoms/GridContainer/GridContainer'));
const BackgroundTransitionBox = dynamic(() => import('@atoms/BackgroundTransitionBox/BackgroundTransitionBox'));
const Row = dynamic(() => import('@atoms/CustomGrid/Row'));
const CustomCard = dynamic(() => import('@atoms/CustomCard/CustomCard'));
const LinkChildren = dynamic(() => import('@atoms/LinkChildren/LinkChildren'));
const CustomButton = dynamic(() => import('@atoms/CustomButton/CustomButton'));

const DocumentCollectionParagraph = (props: FieldParagraphProps) => {
  const {
    fieldParagraph,
    fieldParagraph: { behavior_settings, field_title, field_medias, field_cta },
  } = props;

  const getFileName = (item: string): string => {
    const regexItalian = /(\d{4})(\d{2})(\d{2})_comunicato_stampa_settimanale_buy_back\.pdf/;
    const regexEnglish = /(\d{4})(\d{2})(\d{2})_comunicato_stampa_settimanale_(buy_back_)?eng\.pdf/;

    if (regexItalian.test(item)) {
      return item.replace(
        regexItalian,
        '$3/$2/$1 - Comunicazione settimanale in merito al piano di acquisto azioni proprie'
      );
    }

    if (regexEnglish.test(item)) {
      return item.replace(regexEnglish, '$2/$3/$1 - Weekly communication regarding share buy-back programme');
    }

    return item;
  };

  const setFileName = (item, fileTitle) => {
    // (item?.field_file_description) ? item?.field_file_description : getFileName(item?.name),
    // se (behavior non impostato o impostato su description)
    // default usare description, se manca usare title
    // se behavior impostato su title usare title

    if (
      (typeof fileTitle === 'undefined' && item?.field_file_description) ||
      (fileTitle === 'description-titles' && item?.field_file_description)
    )
      return item?.field_file_description;

    return getFileName(item?.name);
  };

  const data = useMemo(() => {
    const newData: DocumentCollectionModel = {
      anchorData: behavior_settings?.style_options?.toc?.label || '',
      title: field_title || '',
      variant: behavior_settings?.style_options?.attachments_layout?.css_class || 'two-columns',
      cta: getFieldLink(field_cta),
      items: field_medias?.map((item) => {
        const isDocument =
          item?.field_media_document?.uri?.url.includes('.pdf') ||
          item?.field_media_document?.uri?.url.includes('.xls');
        const external = !/internal:/.test(item?.field_link?.uri) && !/entity:/.test(item?.field_link?.uri);
        const fileTitle = behavior_settings?.style_options?.attachments_title?.css_class;

        return {
          variant: 'document-card',
          tagLabel: item?.field_tag?.name,
          ...(item?.field_caption && { createdAtDate: item?.revision_created }),
          caption: item?.field_caption,
          title: setFileName(item, fileTitle),

          iconName: isDocument
            ? item?.field_media_document?.uri?.url.includes('.xls')
              ? 'download'
              : 'picture_as_pdf'
            : external
            ? 'north_east'
            : 'east',
          url: isDocument ? absoluteUrl(item?.field_media_document?.uri?.url) : item?.field_link?.url,
          isExternal: external || isDocument ? true : false,
          target: external || isDocument ? '_blank' : '_self',
        };
      }),
    };

    return newData;
  }, [fieldParagraph]);

  if (!data) return null;

  return (
    <BackgroundTransitionBox
      anchor-data={transformTitleId(data.anchorData || '')}
      transition={data.transition}
      colorVariant="light"
      linesDirection={data.direction}
    >
      <GridContainer sx={{ py: { xs: theme.spacing(80), lg: !!data?.title ? theme.spacing(104) : theme.spacing(80) } }}>
        <Row rowGap={{ xs: theme.spacing(8), lg: theme.spacing(16) }}>
          {!!data?.title && (
            <Grid item xs={12}>
              <Typography
                textAlign="center"
                component="h4"
                mb={{ xs: theme.spacing(32), lg: theme.spacing(48) }}
                variant="h4Medium"
              >
                {data.title}
              </Typography>
            </Grid>
          )}
          {data.variant === 'one-column' &&
            data.items?.map((item, index) => (
              <Fragment key={index}>
                <Grid item xs={0} lg={1} />
                <Grid item xs={12} lg={10}>
                  <LinkChildren url={item.url} target={item.target} isExternal={item.isExternal} rel={item.rel}>
                    <CustomCard {...item} />
                  </LinkChildren>
                </Grid>
                <Grid item xs={0} lg={1} />
              </Fragment>
            ))}
          {data.variant === 'two-columns' && (
            <>
              {data?.items?.map((item, index) => (
                <Grid key={index} item xs={12} lg={6}>
                  <LinkChildren url={item.url} target={item.target} isExternal={item.isExternal} rel={item.rel}>
                    <CustomCard {...item} />
                  </LinkChildren>
                </Grid>
              ))}
            </>
          )}
          {data?.cta?.url && (
            <Grid item xs={12} mt={{ xs: 32, lg: 96 }} display="flex" alignItems="center" justifyContent="center">
              <CustomButton
                text={data.cta?.label}
                component="a"
                href={data.cta?.url}
                target={data.cta.target}
                variant="large"
                color={'secondary'}
              />
            </Grid>
          )}
        </Row>
      </GridContainer>
    </BackgroundTransitionBox>
  );
};

export default DocumentCollectionParagraph;
